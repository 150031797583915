import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService, NbToastrService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GeneralService, Company, User, Vendor } from '../../../services/general.service';
import {UsersService} from '../../../services/users.service';
import {RequestService } from '../../../services/';
@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any = { admin: 1, email: "test@test.com", name: "Usuario Test"};
  showTooltip = true;

  // VARIABLES - DATOS
  company: Company = { id: "1", vendor_id: "1", label: "MOP", logo_min: "mop.png", logo_max: "" };
  users: User = { admin: 1, email: "test@test.com", name: "Usuario Test"};
  vendor: Vendor;

  // HYPERLINK
  hyperConfig = [];
  hyperlinkConfigMap = <any>{};
  url: string = '';
  runHyperConfig: boolean = true;


  userMenu = [ { title: 'Sign off' } ];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private userService: UserData,
              private layoutService: LayoutService,
              public _General: GeneralService,
              private req: RequestService,
              private toastrService: NbToastrService,
              private usersService: UsersService) {
    this._General.onGeneralUpdated().subscribe((data) => {
      if (data == null) {
        this.company = { id: "1", vendor_id: "1", label: "MOP", logo_min: "mop.png", logo_max: "" };
        this.users = { admin: 1, email: "test@test.com", name: "Usuario Test"};
        this.vendor = null;
      } else {
        this.company = { id: "1", vendor_id: "1", label: "MOP", logo_min: "mop.png", logo_max: "" };
        this.users = { admin: 1, email: "test@test.com", name: "Usuario Test"};
        this.vendor = data.vendor;

        // tslint:disable-next-line: radix
        // if (this.runHyperConfig && +this.users.admin !== 1) {this.report(`visit`); this.runHyperConfig = false; } // EVITANDO ADMINS
        if (this.runHyperConfig && +this.users.admin !== 1) {
          const father = this;
          document.body.addEventListener('click', function(event) {
            father.handleClick(event.target);
          }, true);
          this.report(`visit`);
          this.runHyperConfig = false;
        } // PARA TODOS LOS USUARIOS


        // console.log('Compañía: ', this.vendor);
        // console.log('Usuarios: ', this.users);
        // console.log('Clientes: ', this.company);
        if (this.company.id === '6' && this.showTooltip) {
          this.toastrService.show(
            'The platform you are viewing is in DEMO mode. The reading of the parameters, alerts and other outputs of the same hace the sole function of showing the functionality of the service and do not correspond to real data of the structure.',
            `Platform in demo mode`, { duration: 60000000, destroyByClick: true },
          );
          this.showTooltip = false;
        }
      }
    });

    menuService.onItemClick().subscribe((data) => {
     
      /*if(data.item.title == "Datos"){
        console.log("hola datos")
        document.querySelector('nb-menu.spaceTop>ul.menu-items>li.menu-item:nth-child(2)').setAttribute('style', 'background: black')
        document.querySelector('nb-menu.spaceTop>ul.menu-items>li.menu-item:nth-child(3)').setAttribute('style', 'background: #e51e29')
        document.querySelector('div.scrollable>nb-menu:first-child>ul.menu-items>li.menu-item:nth-child(2)').setAttribute('style', 'background: #e51e29')
      }else if(data.item.title == "Configuración"){
        console.log("hola config")
        document.querySelector('nb-menu.spaceTop>ul.menu-items>li.menu-item:nth-child(2)').setAttribute('style', 'background: #e51e29')
        document.querySelector('nb-menu.spaceTop>ul.menu-items>li.menu-item:nth-child(3)').setAttribute('style', 'background: black')
        document.querySelector('div.scrollable>nb-menu:first-child>ul.menu-items>li.menu-item:nth-child(2)').setAttribute('style', 'background: #e51e29')
      }else if(data.item.title == "Instalaciones"){
        console.log("hola inst")
        document.querySelector('div.scrollable>nb-menu:first-child>ul.menu-items>li.menu-item:nth-child(2)').setAttribute('style', 'background: black')
        document.querySelector('nb-menu.spaceTop>ul.menu-items>li.menu-item:nth-child(2)').setAttribute('style', 'background: #e51e29')
        document.querySelector('nb-menu.spaceTop>ul.menu-items>li.menu-item:nth-child(3)').setAttribute('style', 'background: #e51e29')
        
      }*/
    });

  }


  // HYPERLINK
  setupHyper(...arg) {
    this.hyperConfig.push(arg);
  }



  report(action, element?) {
    const request = new XMLHttpRequest();

    if (encodeURIComponent(action) === 'visit') {
      this.url = 'https://usehyperlink.com/api?action=visit&key=LgLGA7pRF9gp1y3MyAGOStSziaq2&pageTitle=' + encodeURIComponent(document.title || '') + '%20-%20' + this.users.name.replace(/ /g, '%20') + '&body=El%20usuario%20' + this.users.name.replace(/ /g, '%20') + '%20ha%20visitado%20la%20pataforma%20a%20través%20de%20{device}&pageHref=' + encodeURIComponent(window.location.href || '') + '%20a%20través%20de%20{device}&config=' + this.hyperlinkConfigMap.config;
      request.open('GET', this.url);
    } else {
      // console.log(element);
      this.url = 'https://usehyperlink.com/api?action=send&key=LgLGA7pRF9gp1y3MyAGOStSziaq2&ttitle=Plataforma%20para%20el%20monitoreo%20de%20puentes%20-%20{city}%20{region}&body=El%20usuario%20"' + this.users.name.replace(/ /g, '%20') + '"%20ha%20hecho%20click%20en%20"' + encodeURIComponent(((element.attributes['hypertag'] && element.attributes['hypertag'].value)) || (element && element.innerText.toUpperCase()) || '') + '"';
      request.open('GET', this.url);
    }
    request.send();
  }


  handleClick(element) {
    // [attr.hypertag]="project.name"
    if (!element) {
      return;
    }

    // if (!element.attributes['hypertag'] && !element.attributes['data-hypertag'] && !(element.innerText && element.tagName && (element.tagName.toLowerCase() === 'a' || element.tagName.toLowerCase() === 'button'))) {
    if (!element.attributes['hypertag'] && !element.attributes['data-hypertag'] && !element.classList.contains('menu-title')) {
      return this.handleClick(element.parentElement);
    }
    this.report(`click`, element);
  }

  // FIN HYPERLINK


  ngOnInit() {
    // HYPERLINK
    this.setupHyper('js', new Date());
    this.setupHyper('config', 'h77hum');

    this.hyperConfig.forEach(config => {
      this.hyperlinkConfigMap[config[0]] = config[1];
    });

    // if (+this.users.admin !== 1){
    // document.body.addEventListener('click', function(event) {
    //   father.handleClick(event.target);
    // }, true);
    // }

    // FIN HYPERLINK


    this._General.getData();

    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = { admin: 1, email: "test@test.com", name: "Usuario Test"});

    this.menuService.onItemClick().subscribe(( event ) => {
      // this.onItemSelection(event.item.title);
    });
  }

  ngOnDestroy() {
    const fatherThis = this;
    this.destroy$.next();
    this.destroy$.complete();
    document.body.removeEventListener('click', function(event) {
      fatherThis.handleClick(event.target);
    }, false);

  }


  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  

}
