import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionService } from './sesion.service';

@Injectable({
  providedIn: 'root'
})
export class JwtInterceptorService  implements HttpInterceptor {
    constructor(private session: SessionService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      // add auth header with jwt if account is logged in and request is to the api url
      const token = this.session.token;
 
      if ( !!token ) {
            request = request.clone({
                setHeaders: { Authorization: `Bearer ${token}` }
            });
        }

        return next.handle(request);
    }
}