import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { Router } from '@angular/router';
import {environment} from '../../environments/environment'
import { TranslateService } from '@ngx-translate/core'

@Injectable({
  providedIn: "root"
})
export class InstalacionesService {

  public activeLang;

  constructor(private http: HttpClient, private router: Router, private translate: TranslateService) {

    this.translate.setDefaultLang(this.activeLang);
  }

  mostrar_instalaciones(id_user:any):Observable<any>{
    return this.http.get(environment.servidor+"/instalaciones/"+id_user)
    //return this.http.get("/instalaciones/"+id_user)
  }

  mostrar_instalaciones_filtro_por_nombre(id:any, id_user:any):Observable<any>{
    return this.http.get(environment.servidor+"/instalaciones/"+id+"/"+id_user)
    //return this.http.get("/instalaciones/"+id+"/"+id_user)
  }

  mostrar_nodos_select(id:any):Observable<any>{
    return this.http.get(environment.servidor+"/nodos/"+id)
    //return this.http.get("/nodos/"+id)
  }

  mostrar_modelo_3d(id:any):Observable<any>{
    return this.http.get(environment.servidor+"/modelo/"+id)
    //return this.http.get("/modelo/"+id)
  }

  mostrar_nodos_data(id:any):Observable<any>{
    return this.http.get(environment.servidor+"/nodosdata/"+id)
    //return this.http.get("/nodosdata/"+id)
  }

  mostrar_nodos_data_general(id:any):Observable<any>{
    return this.http.get(environment.servidor+"/nodosdatageneral/"+id)
    //return this.http.get("/nodosdatageneral/"+id)
  }

  mostrar_nodos_data_last_week(id:any):Observable<any>{
    return this.http.get(environment.servidor+"/nodosdatalastweek/"+id)
    //return this.http.get("/nodosdatageneral/"+id)
  }

  mostrar_nodos_data_last_year(id:any):Observable<any>{
    return this.http.get(environment.servidor+"/nodosdatalastyear/"+id)
    //return this.http.get("/nodosdatageneral/"+id)
  }

  insertar_config(incl_ini_l:any, incl_ini_t:any, ln_junta:any, l_inst:any, dh_ini:any, dv_ini:any, dir_v:any, dir_l:any, dir_t:any, id:any):Observable<any>{
    return this.http.post<any>(environment.servidor+"/config", {incl_ini_l, incl_ini_t, ln_junta, l_inst, dh_ini, dv_ini, dir_v, dir_l, dir_t, id})
    //return this.http.post<any>("/config", {incl_ini_l, incl_ini_t, ln_junta, l_inst, dh_ini, dv_ini, dir_v, dir_l, dir_t, id})
  }

  insertar_frec(frec:any, id:any):Observable<any>{
    return this.http.post<any>(environment.servidor+"/configFrec", {frec, id})
    //return this.http.post<any>("/configFrec", {frec, id})
  }

  mostrar_data_config(id:any):Observable<any>{
    return this.http.get(environment.servidor+"/obtenerconfig/"+id)
    //return this.http.get("/obtenerconfig/"+id)
  }

  mostrar_frec_inst(id:any):Observable<any>{
    return this.http.get(environment.servidor+"/frecInst/"+id)
    //return this.http.get("/frecInst/"+id)
  }

  insertar_edificio_nuevo(nombre:any, mac:any, ciudad:any, pais:any, coord_x:any, coord_y:any, img:any, urlModelo3d:any, frec:any, id_usuario:any):Observable<any>{
    return this.http.post<any>(environment.servidor+"/edificioNuevo", {nombre, mac, ciudad, pais, coord_x, coord_y, img, urlModelo3d, frec, id_usuario})
    //return this.http.post<any>("/edificioNuevo", {nombre, mac, ciudad, pais, coord_x, coord_y, img, urlModelo3d, frec, id_usuario})
  }

  insertar_nodo_nuevo(nom_nodo:any, id_instalacion:any, mac:any, incl_ini_l:any, incl_ini_t:any, ln_junta:any, l_inst:any, dh_ini:any, dv_ini:any, dir_v:any, dir_l:any, dir_t:any,):Observable<any>{
    return this.http.post<any>(environment.servidor+"/nodoNuevo", {nom_nodo, id_instalacion, mac, incl_ini_l, incl_ini_t, ln_junta, l_inst, dh_ini, dv_ini, dir_v, dir_l, dir_t})
    //return this.http.post<any>("/nodoNuevo", {nom_nodo, id_instalacion, mac, incl_ini_l, incl_ini_t, ln_junta, l_inst, dh_ini, dv_ini, dir_v, dir_l, dir_t})
  }

  ultimo_id_edificio():Observable<any>{
    return this.http.get(environment.servidor+"/ultimoId")
    //return this.http.get("/ultimoId")
  }

  insertar_img_edificio(image: File):Observable<any>{
    const formData = new FormData();

    formData.append('image', image);

    return this.http.post<any>(environment.servidor+"/uploadImg", formData)
    //return this.http.post<any>("/uploadImg", formData)
  }

  login(usuario:any):Observable<any>{
    return this.http.get(environment.servidor+"/login/"+usuario)
    //return this.http.get("/login/"+usuario)
  }

  insertar_usuario_nuevo(nombre:any, empresa:any, tlf:any, usuario:any, contr:any, token:any):Observable<any>{
    return this.http.post<any>(environment.servidor+"/usuarioNuevo", {nombre, empresa, tlf, usuario, contr, token})
    //return this.http.post<any>("/usuarioNuevo", {nombre, empresa, tlf, usuario, contr, token})
  }

  generarToken(user:any):Observable<any>{
    return this.http.post<any>(environment.servidor+"/generateToken", {user})
    //return this.http.post<any>("/generateToken", {user})
  }

  verificarToken(token:any):Observable<any>{
    return this.http.post<any>(environment.servidor+"/verifyToken", {token})
    //return this.http.post<any>("/verifyToken", {token})
  }
  

  public cambiarLenguaje(evento) {
    //console.log(evento)
    this.activeLang = evento;
    this.translate.use(evento);
    localStorage.setItem('idioma', evento);

    console.log(this.activeLang)
    
    window.location.reload();
    
  }

}