import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { evoData, generalData, initData, registro } from '../pages/model/model';
import { RequestService } from '../shared/services/request.service';
import { data_source } from '../../environments/datavine.source';
import { Projects } from './general.service';
import { Router } from '@angular/router';

@Injectable()
export class GeneralDataService {

  selectedMuelleObj: initData | null = null;

  selectedEdificio!:number;

  selectedMuelleName!:string;

  constructor(private router: Router, private request:RequestService) {

   this.selectedEdificio = parseInt(localStorage.getItem('selectedEdificio'));

    /*if (id != null) {
      this.selectedMuelle = parseInt(id);
      
    } else {
      this.selectedMuelle = null;
    }*/

    /*let name = localStorage.getItem('selectedMuelleName');
    if (id != null) {
      this.selectedMuelleName = name;
    } else {
      this.selectedMuelle = null;
    }*/


  

   }

  ///////////////////////////////////////////////////////////////

  on_muelles_loaded_sub: Subject<initData> = new Subject();

  public on_muelles_loaded(): Observable<initData> {
    return this.on_muelles_loaded_sub.asObservable();
  }

  on_general_data_loaded_sub: Subject<generalData> = new Subject();//un objeto donde puedes enviar y recibir datos

  public on_general_data_loaded(): Observable<generalData> {//devuelve el subject
    return this.on_general_data_loaded_sub.asObservable();
  }

  on_evo_data_loaded_sub: Subject<evoData> = new Subject();

  public on_evo_data_loaded(): Observable<evoData> {
    return this.on_evo_data_loaded_sub.asObservable();
  }

  on_register_data_loaded_sub: Subject<registro> = new Subject();

  public on_register_data_loaded(): Observable<registro> {
    return this.on_register_data_loaded_sub.asObservable();
  }

  //////////////////////////////////////////////////////////////Estos metodos siempre van junto a metodos de Promise

 /* getGenData(){

    this.selectedMuelle = parseInt(localStorage.getItem('selectedMuelle'));
    this.getGeneralP(this.selectedMuelle)//este metodo tiene que tener datos, porque sino se queda esperando
      .then( (v:any) => {

        this.on_general_data_loaded_sub.next(v);
 
        
  })
  }*/

  getEvoData(){
    this.getEvolucionP(this.selectedEdificio)
      .then( (v:any) => {

        this.on_evo_data_loaded_sub.next(v);
 
        
  })
  }

  getRegData(){
    this.getRegistroP(this.selectedEdificio)
      .then( (v:any) => {

        this.on_register_data_loaded_sub.next(v);
 
       
  })
  }

  getMuelles(){
    this.getMuellesP()
      .then( (v:any) => {

        this.on_muelles_loaded_sub.next(v);
 
        
  })
  }

  projectSelected(project: any) {
    this.selectedEdificio = project.id;
    console.log( this.selectedEdificio)
    localStorage.setItem('selectedEdificio', `${project.id}`);
    this.selectedMuelleObj = project;
    //this.router.navigate(['/pages/general/']);
  }

  ///////////////Promises//////////////////////7

  getMuellesP():Promise<any>{
    // promise de la request de los vinedos a la api
    return new Promise<any>( (resolve, reject) => {
      this.request.makeApiGetRequest('login', data_source.token ).subscribe((d:any) => {
        
        resolve(d);
      })
    }); 
  }

  getGeneralP(muelle:number):Promise<any>{
    // promise de la request de los vinedos a la api
    return new Promise<any>( (resolve, reject) => {
      this.request.makeApiGetRequest(`citrico/${muelle}/general`, data_source.token ).subscribe((d:any) => {// con esto nos conectamos al back y hacemos un get a la ruta que hemos definido en el back
        
        resolve(d);// devuelve la respuesta del servidor
      })
    }); 
  }

  getRegistroP(muelle:number):Promise<any>{
    // promise de la request de los vinedos a la api
    return new Promise<any>( (resolve, reject) => {
      this.request.makeApiGetRequest(`citrico/${muelle}/registros`, data_source.token ).subscribe((d:any) => {
        
        resolve(d);
      })
    }); 
  }

  getEvolucionP(muelle:number):Promise<any>{
    // promise de la request de los vinedos a la api
    return new Promise<any>( (resolve, reject) => {
      this.request.makeApiGetRequest(`citrico/${muelle}/evolucion`, data_source.token ).subscribe((d:any) => {
        
        resolve(d);
      })
    }); 
  }

}
