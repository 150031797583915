import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { Router } from '@angular/router';
import {environment} from '../../environments/environment'

@Injectable({
  providedIn: "root"
})
export class UsersService {
  constructor(private http: HttpClient, private router: Router) {}

  logout(){

    localStorage.removeItem('usuario');
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    localStorage.removeItem('selectedEdificio');
    this.router.navigate([environment.servidor+'/pages/login']);
    //window.location.reload();
  }

  info(){

    this.router.navigate(['/pages/guia-de-la-plataforma']);
  }
}