import { NbMenuItem } from '@nebular/theme';

let idioma: String = "";
let menu_title: string = "";
idioma = localStorage.getItem("idioma")

if(idioma == "es"){
    menu_title = 'INSTALACIONES'
}else if(idioma == "en"){
    menu_title = 'INSTALATIONS'
}else if(idioma == "fr"){
    menu_title = 'INSTALLATIONS'
}else if(idioma == "it"){
    menu_title = 'STRUTTURE'
}

export const MENU_ITEMS: NbMenuItem[] = [

  /*{
    title: 'BuildingEye',
    group: true,
  },*/
  {
    title: menu_title,
    icon: 'arrow-ios-downward-outline',
    link: '/pages/home',
    home: true,
  },
 /* {
    title: 'HOLA',
    icon: 'arrow-ios-downward-outline',
    link: '/pages/general',
    home: true,
  },*/
];


export let menu_dynamic: NbMenuItem[] = [];


export function setMenu(modules: NbMenuItem[]): void {
  // CONSTRUYE EL MENÚ A PARTIR DE UN VECTOR
  clearMenu();
  modules.forEach( (element) => {
    menu_dynamic.push(element);
  });
}


export function clearMenu(): void {
  menu_dynamic.length = 0;
}




