import { Injectable } from '@angular/core';
import { RequestService } from './request.service';

import { data_source } from '../../../environments/datavine.source';

function getToken():string{
  
    return data_source.token;
  
}

function saveToken(token:string){
  localStorage.setItem('TOKEN', token);
}

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  
  token:string = getToken();

  constructor(private request: RequestService) {}

  clearToken() {
    this.token = '';
    saveToken('');
  }

  public sessionLogin(user: string, password: string, f: any) {
    this.request
      .makeApiPostRequest(
        'session/login',
        { user: user, password: password },
        null
      )
      .subscribe((d: any) => {
        /* d = Token | False */
 
        if (!!d) {
          this.token = d;
          saveToken(d);
        } else {
          this.clearToken();
        }
        // !! = to_bool('dijiste que te acordarias')
        f(!!d);
      });
  }

  public sessionCheck(f: any) {
    /* SI NO TIENE SESION (TOKEN)
    1.- NO SE HACE LA PETICION!!!!!   ! (IMPORTANTE)
    2.- SI SI QUE TIENE SESIOIN (TOKEN)
    3.- ENTONCES PREGUNTAS A LA API SI TODAVIA SIRVE (RESPUESTA ES BOOLEAN)
    4.- SI TE DICE QUE SI, GUARDAS EL TOKEN
    5.- SI LA API DICE FALSE O NULL, LA TIRAS
    */
    if (this.token) {
      this.request
        .makeApiGetRequest('session/check', this.token)
        .subscribe((d: any) => {
          if (!d) {
 
            this.clearToken();
          }
          f(!!d);
        });
    } else {
 
      f(false);
    }
  }

  public sessionClose(f: any) {
    this.request
      .makeApiGetRequest('session/close', this.token)
      .subscribe((d: any) => {
        f(d);
        this.clearToken();
      });
  }
}
