import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { JwtInterceptorService } from "./jwt-interceptor.service";
import { RequestService } from "./request.service";
import { SessionService } from "./sesion.service";




export const SERVICES:any[] = 
[
    RequestService,
    SessionService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true },
];

export default SERVICES;