import { Injectable, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from './menu.service';

import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {RequestService} from './request.service';
import { Observable } from 'rxjs';

@Injectable()

export class GeneralService {
  ProjectSelected: any;
  constructor(private router: Router,  private _Menu: MenuService, private request: RequestService) {}

  // VECTORES PARA GUARDAR RESPUESTA DE LA PETICIÓN
  private menu: Menu;
  private projects: any;
  private vendor: Vendor;
  private prod: Boolean;


  private on_general_updated: BehaviorSubject<any> = new BehaviorSubject(null);

  public onGeneralUpdated(): Observable<any> {
    return this.on_general_updated.asObservable();
  }

  // OBTENEMOS LOS DATOS
  getData() {
    // pedimos los datos
    
      // console.log('respuesta de downloads', d);
      // cuando los obtenemos, avisamos a los que se hayan
      // suscrito(en este caso solo el componente informes)
      // para actualizar los datos de descargas y los pinte
      // d = d.map( e =>  {e.id = parseInt(e.id);return e;}) //TIPO DE MAPEO
      this.on_general_updated.next([prueba]);
      // this.company = d.company;
      // this.menu = d.menu;
      this.projects = [prueba];
      // this.vendor = d.vendor;
      // this.prod = d.prod;
    
  }


  // MÉTODOS PARA ACCEDER A LOS DATOS
  getCompany(): Company {
    return { id: "1", vendor_id: "1", label: "", logo_min: "", logo_max: "" };
  }

  getMenu(): Menu {
    return this.menu;
  }

  getProjects(): any {
    return this.projects;
  }

  getUser(): User {
    return { admin: 1, email: "test@test.com", name: "Usuario Test"};
  }

  getVendor(): Vendor {
    return this.vendor;
  }

  getProd() {
    return this.prod;
  }



}

export interface Company {
  id: string;
  label: string;
  logo_max: string;
  logo_min: string;
  vendor_id: string;
}

export interface Menu {
  title: string;
  link: string;
  icon: string;
  expanded: string;
  children:
    {
      children: any;
      expanded: string;
      icon: string;
      link: string;
      title: string;
    }[];
}[];

export const prueba:Projects = {
  id:1,
  name: 'Muelle Tenerife',
  town: 'Tenerife',
  image_name: '../assets/images/project_image/1.jpg',
  coord: {
    x: '28.462599',
    y: '-16.246745',
  },


}




export interface Projects {
  // alerts: number;
  id: number;
  name: string;
  // ini_date: string;
  town: string;
  // nnodes: string;
  // state: number;
  image_name: string;
  coord:
    {
      x: string;
      y: string;
    };
}[];


export interface User {
  admin: number;
  email: string;
  name: string;
}



export interface Vendor {
  id: string;
  label: string;
  logo_max: string;
  logo_min: string;
  web_site: string;
}
