import { Injectable } from '@angular/core';
import { initData } from '../pages/model/model';
import {  setMenu, clearMenu } from '../pages/pages-menu';

import { Menu } from './general.service';

@Injectable()

export class MenuService {
  modules = [];
  modulesAdmin = [];
  constructor() {}
  public idioma;
  public menu_datos;
  public menu_config;

  getMenu( project:initData ) {

    this.idioma = localStorage.getItem("idioma")

    if(this.idioma == "es"){
        this.menu_datos = "Datos"
        this.menu_config = "Configuración"
    }else if(this.idioma == "en"){
        this.menu_datos = "Data"
        this.menu_config = "Settings"
    }else if(this.idioma == "fr"){
        this.menu_datos = "Données"
        this.menu_config = "Paramètre"
    }else if(this.idioma == "it"){
        this.menu_datos = "Dati"
        this.menu_config = "Impostazione"
    }

    this.modules = [
      {
        title: project.nombre,
        //expanded: false,
        group: false,
        icon: 'arrow-ios-downward-outline',
        children: [
          {
            title: this.menu_datos,
            group: false,
            link: '/pages/datos',
            //icon: 'arrow-ios-downward-outline',
          },
          {
            title: this.menu_config,
            group: false,
            link: '/pages/configuracion',
            //icon: 'arrow-ios-downward-outline',
          },
        ],
      },
      /*{
        title: 'DATOS',
        group: false,
        link: '/pages/datos',
        icon: 'arrow-ios-downward-outline',
      },
      {
        title: 'CONFIGURACIÓN',
        group: false,
        link: '/pages/configuracion',
        icon: 'arrow-ios-downward-outline',
      },*/
    ]


    setMenu(this.modules);
  }

  setMenuC(){
    setMenu(this.modules);
  }


  vaciaMenu() {
    clearMenu();
  }





}


// this.modules = [
//   {
//     // title: label.toUpperCase(),
//     title: nameProject.toUpperCase(),
//     group: true,
//   },

  // {
  //   title: 'General',
  //   icon: 'home-outline',
  //   expanded: true,
  //   children: [
  //     {
  //       title: 'Puente',
  //       icon: 'chevron-right-outline',
  //       link: '/pages/general/puente',
  //     },
  //     {
  //       title: 'Parámetros',
  //       icon: 'chevron-right-outline',
  //       link: '/pages/general/parametros',
  //     },
  //     {
  //       title: 'Estado Inicial',
  //       icon: 'chevron-right-outline',
  //       link: '/pages/general/estado-inicial',
  //     },
  //   ],
  // },
  // {
  //   title: 'Evolución de los parámetros',
  //   icon: 'pie-chart-outline',
  //   link: '/pages/registros/graficas',
  // },
  // {
  //   title: 'Registros',
  //   icon: 'activity-outline',
  //   link: '/pages/registros2/graficas',
  // },
  // {
  //   title: 'Descargas',
  //   icon: 'cloud-download-outline',
  //   link: '/pages/descargas',
  // },
  // {
  //   title: 'Historial',
  //   icon: 'alert-triangle-outline',
  //   link: '/pages/historial/alertas',
  // },
  // {
  //   title: 'Simulaciones',
  //   icon: 'cube-outline',
  //   link: '/pages/simulaciones/solicitar',
  // },
// ];