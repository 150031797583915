import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// import { h } from 'ionicons/dist/types/stencil-public-runtime';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { data_source } from '../../../environments/datavine.source'

let base_url = data_source.url;
let ruta = base_url + 'api/';


const httpOptions = new HttpHeaders({
  'Content-Type': 'application/json',
});


function getHeader(token:string|null){

    let headers:any = {headers:httpOptions}
 
 
 
 

    return headers;
}

export class Subscriber {

  constructor(public http: Observable<any>, protected no_session: Subject<void>) {
  }

  static makeGet(
    httpc: HttpClient,
    url: string,
    op: string = 'error',
    no_session: Subject<void>,
    token: string|null
  ): Subscriber {
 
    const req = httpc.get<any>(ruta + url, getHeader(token));
    const sus = new Subscriber(req, no_session);
    sus.pipe(op);

    return sus;
  }

  static makePost(
    httpc: HttpClient,
    url: string,
    data: any,
    op: string = 'error',
    no_session: Subject<void>,
    token: string|null
  ): Subscriber {
    const req = httpc.post<any>(ruta + url, data, getHeader(token) );
    const sus = new Subscriber(req, no_session);
    sus.pipe(op);

    return sus;
  }

  private map(d: Respuesta) {
 
 
    if (d.session === false) {
      this.no_session.next();
      return -1;
    } else if (!d.ok) {
      return false;
    }
    return d.data;
  }

  pipe(op: string): Observable<any> {
    this.http = this.http.pipe(
      map((d) => this.map(d)),
      catchError(this.handleError<any>(op, false))
    );
    return this.http;
  }

  subscribe(a: any): any {
    return this.http.subscribe(a);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead

      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  log(a: any) {
    alert(a);
  }
}

export interface Respuesta {
  ok: boolean;
  session?: boolean;
  data: any;
}

function mapResponse(d:any, router:Router){
 
 
    if (d.session === false) {
        // router.navigate(['/auth/login']);
        return -1;
      } else if (!d.ok) {
        return false;
      }
      return d.data;
}


@Injectable({
  providedIn: 'root',
})
export class RequestService {
  constructor(private http: HttpClient) {}

  private on_session_invalid_sub: Subject<void> = new Subject();

  public on_session_invalid(): Observable<void> {
    return this.on_session_invalid_sub.asObservable();
  }

  

  makeApiGetRequest(url: string, token: string|null) {

    return Subscriber.makeGet(
      this.http,
      url,
      `Get ${url}`,
      this.on_session_invalid_sub,
      token
    );
  }

  makeApiPostRequest(url: string, params: any, token: string|null) {

    return Subscriber.makePost(
      this.http,
      url,
      params,
      `API REQ ${url}`,
      this.on_session_invalid_sub,
      token
    );
  }
}
