export * from './descargas.service';
export * from './menu.service';
export * from './request.service';
//export * from './auth.guard.service';
//export * from './auth.service';

import {GeneralService} from './general.service';
import {DescargasService} from './descargas.service';
import {MenuService} from './menu.service';
import {PuentesService} from './puente.service';
import {RequestService} from './request.service';
import { GeneralDataService } from './general-data.service';
//import {AuthGuard } from './auth.guard.service';
//import {AuthService } from './auth.service';


export const SERVICES = [
    DescargasService,
    MenuService,
    PuentesService,
    RequestService,
    GeneralService,
    GeneralDataService
//    AuthGuard,
//    AuthService
];
