import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import SERVICES from './services';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,

  ],
  providers:[
    ...SERVICES
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return <ModuleWithProviders<SharedModule>>{
      ngModule: SharedModule,
      providers: [
        ...SERVICES
      ]
    };
  }
}
